import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import Transit from "../assets/img/transitbuddy.png";
import RandoFit from "../assets/img/randofit.png";
import Portfolio from "../assets/img/portfolio.png";
import HtmlBuilder from "../assets/img/htmltopdf.jpeg";
import TaskDash from "../assets/img/taskdash.png";
import DjangoPort from "../assets/img/DjangoPort.png";
import Wink from "../assets/img/winkmotorsscreen.png";
import Doyglon from "../assets/img/doyglon.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const reactProjects = [
    {
      title: "TransitBuddy",
      description: "A full stack public transit accessibility application that let's users report and avoid obstacles on their commute.",
      stack: 'React, Node, Express, MySQL, HTML/CSS',
      imgUrl: Transit,
      link: "https://transitbuddy.netlify.app/"
    },
    {
      title: "RandoFit",
      description: "A React application that let's users generate a random workout, swapping out individual exercises as necessary.",
      stack: 'React, HTML/CSS',
      imgUrl: RandoFit,
      link: "https://inspiring-jones-2a1193.netlify.app/"
    },
    {
      title: "This Portfolio",
      description: "C'mon. Of course I'm not gonna miss this lay-up. Single page react app with animation and responsive design.",
      stack: 'React, React Bootstrap, HTML/CSS',
      imgUrl: Portfolio,
      link: ""
    },

    
  ];

  const sweProjects = [
    {
      title: "TaskDash",
      description: "An assignment manager with user authentication",
      stack: 'Django, Python, Bootstrap',
      imgUrl: TaskDash,
      link: "https://task-dash.herokuapp.com/"
    },
    {
      title: "Django Portfolio",
      description: "A simple Django version of my portfolio with a blog app",
      stack: 'Django, Python, Bootstrap',
      imgUrl: DjangoPort,
      link: "https://django-fs-port.herokuapp.com/"
    },
    
  ];

  const webProjects = [
    {
      title: "Doyglon",
      description: "A sample site made for a digital ADHD treatment company",
      stack: 'Vue, CSS, HTML',
      imgUrl: Doyglon,
      link: "https://jester-polygon-test.netlify.app/#/"
    },
    {
      title: "Wink Motors",
      description: "An eCommerce store for an automotive company",
      stack: 'Shopify, Liquid, HTML/CSS',
      imgUrl: Wink,
      link: "https://winkmotors.com"
    },
    {
      title: "HTML Builder",
      description: "A Ruby script that lets the user generate an HTML file and convert it to PDF",
      stack: 'Ruby',
      imgUrl: HtmlBuilder,
      link: "https://replit.com/@StewartJester/HTML-PDF-converter-DEMO"
    },
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility once partialVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <Tab.Container classNamez="proj-tabs" id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">React</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Django</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Others</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp project-boxes" : "project-boxes"}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          reactProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          sweProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          webProjects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}