import { Col, Row } from "react-bootstrap";

export const ProjectCard = ({ title, description, stack, imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">

          

          <Row><h4 className="project-title">{title}</h4></Row>
          <Row><span className="project-desc">{description}</span></Row>
          <Row><span className="project-stack">{stack}</span></Row>
          <Row><span className="project-link"><a href={link} target="_blank">Link</a></span></Row>
        </div>
      </div>
    </Col>
  )
}
