import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import alien from "../assets/img/prof-pic-edit.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100000);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Software Engineer", "Web Developer"];
  const period = 2000;

  var ReactRotatingText = require('react-rotating-text');

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 10);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className= "animate__animated animate__fadeIn">
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hi! I'm Stewart`} </h1>
                <h2><ReactRotatingText pause='3000' items={['Software Engineer', 'Web Developer']} /></h2>
              {/* <h2> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Software Engineer", "Web Developer", "Digital Content Specialist" ]'><span className="wrap">{text}</span></span></h2> */}
                  <p>I am a software engineer and web developer based in Los Angeles. My experience covers everything from full stack development with React and Node to work with CMS tools like WordPress and Shopify. </p>
                  <button><a className="connect-link" href="#connect">Let’s Connect <ArrowRightCircle size={25} /></a></button>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            
            <TrackVisibility>
              {({ isVisible }) =>
                <div className="animate__animated animate__rubberBand">
                  <img className="profpic" src={alien} alt="Header Img"/>
                </div>}
            </TrackVisibility>
  
          </Col>
        </Row>
      </Container>
    </section>
  )
}
